import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Routes } from 'react-router-dom';
import { ForgetPassword } from './pages/ForgetPassword/ForgetPassword';
import {
    new_user_password_path,
    new_user_session_path,
    user_password_path,
    new_user_registration_path,
    user_registration_path,
    enter_login_code_path,
    login_code_sign_in_path,
    users_password_edit_embedded_path,
    edit_user_password_path,
    new_submit_path,
    activate_path,
    users_sign_in_path,
} from './adapter/window/routes';
import { SignInWithPassword } from './pages/SignInPassword/SignInWithPassword';
import { SignUp } from './pages/SignUp/SignUp';
import { EmailEnter } from './pages/PasswordLess/EmailEnter';
import { EnterCode } from './pages/PasswordLess/EnterCode';
import { AppContext } from './AppContext';
import { EditPasswordEmbeddedPage } from './pages/Password/EditPasswordEmbeddedPage';
import { ResetPassword } from './pages/Password/ResetPassword';
import { ForgetPasswordSubmit } from './pages/ForgetPassword/ForgetPasswordSubmit';
import { LocationChangeDataCleaner } from './components/LocationChangeDataCleaner';
import { Redirect } from './components/Redirect';
import { VerificationPage } from './pages/Verification/Page';
import { SignIn } from './pages/SignIn/SignIn';

const mountPoint = document.getElementById('root_app_mount');
if (mountPoint) {
    ReactDOM.render(
        <React.StrictMode>
            <AppContext>
                <Routes>
                    <Route path={new_user_password_path()} element={<ForgetPassword />} />
                    <Route path={user_password_path()} element={<Redirect to={edit_user_password_path()} />} />
                    <Route path={users_sign_in_path()} element={<SignIn />} />
                    <Route path={new_user_session_path()} element={<SignInWithPassword />} />
                    <Route path={new_user_registration_path()} element={<SignUp />} />
                    <Route path={user_registration_path()} element={<Redirect to={new_user_registration_path()} />} />
                    <Route path={login_code_sign_in_path()} element={<EmailEnter />} />
                    <Route path={enter_login_code_path()} element={<EnterCode />} />
                    <Route path={edit_user_password_path()} element={<ResetPassword />} />
                    <Route path={users_password_edit_embedded_path()} element={<EditPasswordEmbeddedPage />} />
                    <Route path={new_submit_path()} element={<ForgetPasswordSubmit />} />
                    <Route path={activate_path()} element={<VerificationPage />} />
                </Routes>
                <LocationChangeDataCleaner />
            </AppContext>
        </React.StrictMode>,
        mountPoint,
    );
}
