// infer Id of helper-text from the parent component Id for cypress testing of errors
function cypressHelperTextId(id: string) {
    return `${id}-helper-text`;
}

interface FormErrorProps {
    errors?: string[];
    id?: string;
}

export function FormError({ errors, id }: FormErrorProps) {
    if (!errors) {
        return null;
    }

    const assignableId = id ? { id: cypressHelperTextId(id) } : null;
    return (
        <span {...assignableId}>
            {errors.map((error) => (
                <>
                    {error}
                    <br />
                </>
            ))}
        </span>
    );
}
