import { PropsWithChildren } from 'react';
import { Typography } from '@customink/pigment-react';

export function AuthDescription({ children }: PropsWithChildren<unknown>) {
    return (
        <Typography variant="bodyLong1" sx={{ fontWeight: 600 }}>
            {children}
        </Typography>
    );
}
