import { styled } from '@mui/material';
import { IconData } from './IconData';

const StyledPath = styled('path')(({ theme }) => ({
    fill: theme.palette.secondary.main,
}));

export function MagicWand({ width, height }: IconData) {
    return (
        <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <StyledPath
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.7998 5.55171L10.5678 7.3197C10.8932 7.64508 10.8918 8.17398 10.5703 8.49552L3.49404 15.5717C3.17002 15.8958 2.6459 15.897 2.31822 15.5693L0.550223 13.8013C0.224849 13.4759 0.226216 12.947 0.547757 12.6255L7.62398 5.54924C7.948 5.22523 8.47212 5.22403 8.7998 5.55171ZM12.5833 5.08335L11.3647 5.72399C10.9556 5.93906 10.6875 5.74331 10.7654 5.2886L10.9982 3.93171L10.0123 2.97076C9.68137 2.64815 9.78467 2.33263 10.2412 2.26629L11.6036 2.06832L12.2129 0.833785C12.4175 0.419332 12.7494 0.420075 12.9536 0.833785L13.5629 2.06832L14.9253 2.26629C15.3827 2.33275 15.4846 2.64873 15.1542 2.97076L14.1684 3.93171L14.4011 5.2886C14.4792 5.74413 14.2102 5.93867 13.8018 5.72399L12.5833 5.08335ZM13.5236 11.4549L12.9101 11.7775C12.5012 11.9924 12.2324 11.8018 12.3112 11.3424L12.4283 10.6592L11.932 10.1754C11.6012 9.85293 11.6995 9.53831 12.1607 9.47129L12.8467 9.37161L13.1535 8.75003C13.3579 8.33582 13.6875 8.33205 13.8938 8.75003L14.2005 9.37161L14.8865 9.47129C15.3436 9.53771 15.449 9.85 15.1152 10.1754L14.6189 10.6592L14.7361 11.3424C14.8141 11.7976 14.5497 11.9944 14.1371 11.7775L13.5236 11.4549ZM5.19048 3.53823L4.57694 3.86078C4.16809 4.07573 3.89924 3.88505 3.97803 3.42565L4.09521 2.74247L3.59884 2.25863C3.26808 1.93621 3.36634 1.6216 3.82761 1.55457L4.51356 1.4549L4.82033 0.833314C5.02476 0.4191 5.35434 0.415335 5.56063 0.833314L5.8674 1.4549L6.55335 1.55457C7.01046 1.62099 7.11589 1.93328 6.78212 2.25863L6.28575 2.74247L6.40293 3.42565C6.48101 3.88092 6.21659 4.07769 5.80402 3.86078L5.19048 3.53823ZM6.69826 8.83682L2.31903 13.2166L2.90378 13.8048L7.28433 9.42291L6.69826 8.83682Z"
            />
        </svg>
    );
}
