import { CircularProgress, styled } from '@mui/material';

const StyledProgress = styled(CircularProgress)(({ theme }) => ({
    color: theme.palette.secondary.main,
    marginRight: theme.spacing(2),
}));

export function ButtonLoader() {
    return <StyledProgress size={24} />;
}
