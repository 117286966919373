import { Link as MuiLink, LinkProps } from '@mui/material';
import { Link as ReactRouterLink } from 'react-router-dom';

export function Link(props: LinkProps) {
    const { href, ...rest } = props;

    // temporarily disable no spreading, remove when we use pigment component
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <MuiLink component={ReactRouterLink} to={href ?? ''} color="secondary" {...rest} />;
}
