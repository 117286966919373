import { PropsWithChildren } from 'react';
import { Typography } from '@customink/pigment-react';

export function AuthText({ children }: PropsWithChildren<unknown>) {
    return (
        <Typography variant="bodyLong2" sx={{ fontWeight: 500 }}>
            {children}
        </Typography>
    );
}
