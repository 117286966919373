/**
 * File generated by js-routes 2.2.0
 * Based on Rails 5.1.7 routes of Profiles::Application
 */
const __jsr = ((that) => {
    const hasProp = (value, key) => Object.prototype.hasOwnProperty.call(value, key);
    let NodeTypes;
    (function (NodeTypes) {
        NodeTypes[NodeTypes["GROUP"] = 1] = "GROUP";
        NodeTypes[NodeTypes["CAT"] = 2] = "CAT";
        NodeTypes[NodeTypes["SYMBOL"] = 3] = "SYMBOL";
        NodeTypes[NodeTypes["OR"] = 4] = "OR";
        NodeTypes[NodeTypes["STAR"] = 5] = "STAR";
        NodeTypes[NodeTypes["LITERAL"] = 6] = "LITERAL";
        NodeTypes[NodeTypes["SLASH"] = 7] = "SLASH";
        NodeTypes[NodeTypes["DOT"] = 8] = "DOT";
    })(NodeTypes || (NodeTypes = {}));
    const Root = that;
    const isBroswer = typeof window !== "undefined";
    const ModuleReferences = {
        CJS: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                module.exports = routes;
            },
            isSupported() {
                return typeof module === "object";
            },
        },
        AMD: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                define([], function () {
                    return routes;
                });
            },
            isSupported() {
                return typeof define === "function" && !!define.amd;
            },
        },
        UMD: {
            define(routes) {
                if (ModuleReferences.AMD.isSupported()) {
                    ModuleReferences.AMD.define(routes);
                }
                else {
                    if (ModuleReferences.CJS.isSupported()) {
                        try {
                            ModuleReferences.CJS.define(routes);
                        }
                        catch (error) {
                            if (error.name !== "TypeError")
                                throw error;
                        }
                    }
                }
            },
            isSupported() {
                return (ModuleReferences.AMD.isSupported() ||
                    ModuleReferences.CJS.isSupported());
            },
        },
        ESM: {
            define() {
                // Module can only be defined using ruby code generation
            },
            isSupported() {
                // Its impossible to check if "export" keyword is supported
                return true;
            },
        },
        NIL: {
            define(routes) {
                Utils.namespace(Root, null, routes);
            },
            isSupported() {
                return !null || !!Root;
            },
        },
        DTS: {
            // Acts the same as ESM
            define(routes) {
                ModuleReferences.ESM.define(routes);
            },
            isSupported() {
                return ModuleReferences.ESM.isSupported();
            },
        },
    };
    class ParametersMissing extends Error {
        constructor(...keys) {
            super(`Route missing required keys: ${keys.join(", ")}`);
            this.keys = keys;
            Object.setPrototypeOf(this, Object.getPrototypeOf(this));
            this.name = ParametersMissing.name;
        }
    }
    const UriEncoderSegmentRegex = /[^a-zA-Z0-9\-._~!$&'()*+,;=:@]/g;
    const ReservedOptions = [
        "anchor",
        "trailing_slash",
        "subdomain",
        "host",
        "port",
        "protocol",
    ];
    class UtilsClass {
        constructor() {
            this.configuration = {
                prefix: "/profiles",
                default_url_options: {},
                special_options_key: "_options",
                serializer: null || this.default_serializer.bind(this),
            };
        }
        default_serializer(value, prefix) {
            if (this.is_nullable(value)) {
                return "";
            }
            if (!prefix && !this.is_object(value)) {
                throw new Error("Url parameters should be a javascript hash");
            }
            prefix = prefix || "";
            const result = [];
            if (this.is_array(value)) {
                for (const element of value) {
                    result.push(this.default_serializer(element, prefix + "[]"));
                }
            }
            else if (this.is_object(value)) {
                for (let key in value) {
                    if (!hasProp(value, key))
                        continue;
                    let prop = value[key];
                    if (this.is_nullable(prop) && prefix) {
                        prop = "";
                    }
                    if (this.is_not_nullable(prop)) {
                        if (prefix) {
                            key = prefix + "[" + key + "]";
                        }
                        result.push(this.default_serializer(prop, key));
                    }
                }
            }
            else {
                if (this.is_not_nullable(value)) {
                    result.push(encodeURIComponent(prefix) + "=" + encodeURIComponent("" + value));
                }
            }
            return result.join("&");
        }
        serialize(object) {
            return this.configuration.serializer(object);
        }
        extract_options(number_of_params, args) {
            const last_el = args[args.length - 1];
            if ((args.length > number_of_params && last_el === 0) ||
                (this.is_object(last_el) &&
                    !this.looks_like_serialized_model(last_el))) {
                if (this.is_object(last_el)) {
                    delete last_el[this.configuration.special_options_key];
                }
                return {
                    args: args.slice(0, args.length - 1),
                    options: last_el,
                };
            }
            else {
                return { args, options: {} };
            }
        }
        looks_like_serialized_model(object) {
            return (this.is_object(object) &&
                !(this.configuration.special_options_key in object) &&
                ("id" in object || "to_param" in object || "toParam" in object));
        }
        path_identifier(object) {
            const result = this.unwrap_path_identifier(object);
            return this.is_nullable(result) || result === false ? "" : "" + result;
        }
        unwrap_path_identifier(object) {
            let result = object;
            if (!this.is_object(object)) {
                return object;
            }
            if ("to_param" in object) {
                result = object.to_param;
            }
            else if ("toParam" in object) {
                result = object.toParam;
            }
            else if ("id" in object) {
                result = object.id;
            }
            else {
                result = object;
            }
            return this.is_callable(result) ? result.call(object) : result;
        }
        partition_parameters(parts, required_params, default_options, call_arguments) {
            // eslint-disable-next-line prefer-const
            let { args, options } = this.extract_options(parts.length, call_arguments);
            if (args.length > parts.length) {
                throw new Error("Too many parameters provided for path");
            }
            let use_all_parts = args.length > required_params.length;
            const parts_options = {
                ...this.configuration.default_url_options,
            };
            for (const key in options) {
                const value = options[key];
                if (!hasProp(options, key))
                    continue;
                use_all_parts = true;
                if (parts.includes(key)) {
                    parts_options[key] = value;
                }
            }
            options = {
                ...this.configuration.default_url_options,
                ...default_options,
                ...options,
            };
            const keyword_parameters = {};
            const query_parameters = {};
            for (const key in options) {
                if (!hasProp(options, key))
                    continue;
                const value = options[key];
                if (this.is_reserved_option(key)) {
                    keyword_parameters[key] = value;
                }
                else {
                    if (!this.is_nullable(value) &&
                        (value !== default_options[key] || required_params.includes(key))) {
                        query_parameters[key] = value;
                    }
                }
            }
            const route_parts = use_all_parts ? parts : required_params;
            let i = 0;
            for (const part of route_parts) {
                if (i < args.length) {
                    const value = args[i];
                    if (!hasProp(parts_options, part)) {
                        query_parameters[part] = value;
                        ++i;
                    }
                }
            }
            return { keyword_parameters, query_parameters };
        }
        build_route(parts, required_params, default_options, route, absolute, args) {
            const { keyword_parameters, query_parameters, } = this.partition_parameters(parts, required_params, default_options, args);
            const missing_params = required_params.filter((param) => !hasProp(query_parameters, param) ||
                this.is_nullable(query_parameters[param]));
            if (missing_params.length) {
                throw new ParametersMissing(...missing_params);
            }
            let result = this.get_prefix() + this.visit(route, query_parameters);
            if (keyword_parameters.trailing_slash) {
                result = result.replace(/(.*?)[/]?$/, "$1/");
            }
            const url_params = this.serialize(query_parameters);
            if (url_params.length) {
                result += "?" + url_params;
            }
            result += keyword_parameters.anchor
                ? "#" + keyword_parameters.anchor
                : "";
            if (absolute) {
                result = this.route_url(keyword_parameters) + result;
            }
            return result;
        }
        visit(route, parameters, optional = false) {
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return this.visit(route[1], parameters, true);
                case NodeTypes.CAT:
                    return this.visit_cat(route, parameters, optional);
                case NodeTypes.SYMBOL:
                    return this.visit_symbol(route, parameters, optional);
                case NodeTypes.STAR:
                    return this.visit_globbing(route[1], parameters, true);
                case NodeTypes.LITERAL:
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        is_not_nullable(object) {
            return !this.is_nullable(object);
        }
        is_nullable(object) {
            return object === undefined || object === null;
        }
        visit_cat(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, left, right], parameters, optional) {
            const left_part = this.visit(left, parameters, optional);
            let right_part = this.visit(right, parameters, optional);
            if (optional &&
                ((this.is_optional_node(left[0]) && !left_part) ||
                    (this.is_optional_node(right[0]) && !right_part))) {
                return "";
            }
            // if left_part ends on '/' and right_part starts on '/'
            if (left_part[left_part.length - 1] === "/" && right_part[0] === "/") {
                // strip slash from right_part
                // to prevent double slash
                right_part = right_part.substring(1);
            }
            return left_part + right_part;
        }
        visit_symbol(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, key], parameters, optional) {
            const value = this.path_identifier(parameters[key]);
            delete parameters[key];
            if (value.length) {
                return this.encode_segment(value);
            }
            if (optional) {
                return "";
            }
            else {
                throw new ParametersMissing(key);
            }
        }
        encode_segment(segment) {
            return segment.replace(UriEncoderSegmentRegex, (str) => encodeURIComponent(str));
        }
        is_optional_node(node) {
            return [NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT].includes(node);
        }
        build_path_spec(route, wildcard = false) {
            let key;
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return "(" + this.build_path_spec(route[1]) + ")";
                case NodeTypes.CAT:
                    return (this.build_path_spec(route[1]) + this.build_path_spec(route[2]));
                case NodeTypes.STAR:
                    return this.build_path_spec(route[1], true);
                case NodeTypes.SYMBOL:
                    key = route[1];
                    if (wildcard) {
                        return (key.startsWith("*") ? "" : "*") + key;
                    }
                    else {
                        return ":" + key;
                    }
                    break;
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                case NodeTypes.LITERAL:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        visit_globbing(route, parameters, optional) {
            const key = route[1];
            let value = parameters[key];
            delete parameters[key];
            if (this.is_nullable(value)) {
                return this.visit(route, parameters, optional);
            }
            if (this.is_array(value)) {
                value = value.join("/");
            }
            const result = this.path_identifier(value);
            return false
                ? result
                : encodeURI(result);
        }
        get_prefix() {
            const prefix = this.configuration.prefix;
            return prefix.match("/$")
                ? prefix.substring(0, prefix.length - 1)
                : prefix;
        }
        route(parts_table, route_spec, absolute = false) {
            const required_params = [];
            const parts = [];
            const default_options = {};
            for (const [part, { r: required, d: value }] of Object.entries(parts_table)) {
                parts.push(part);
                if (required) {
                    required_params.push(part);
                }
                if (this.is_not_nullable(value)) {
                    default_options[part] = value;
                }
            }
            const result = (...args) => {
                return this.build_route(parts, required_params, default_options, route_spec, absolute, args);
            };
            result.requiredParams = () => required_params;
            result.toString = () => {
                return this.build_path_spec(route_spec);
            };
            return result;
        }
        route_url(route_defaults) {
            const hostname = route_defaults.host || this.current_host();
            if (!hostname) {
                return "";
            }
            const subdomain = route_defaults.subdomain
                ? route_defaults.subdomain + "."
                : "";
            const protocol = route_defaults.protocol || this.current_protocol();
            let port = route_defaults.port ||
                (!route_defaults.host ? this.current_port() : undefined);
            port = port ? ":" + port : "";
            return protocol + "://" + subdomain + hostname + port;
        }
        current_host() {
            var _a;
            return (isBroswer && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.hostname)) || "";
        }
        current_protocol() {
            var _a, _b;
            return ((isBroswer && ((_b = (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.protocol) === null || _b === void 0 ? void 0 : _b.replace(/:$/, ""))) || "http");
        }
        current_port() {
            var _a;
            return (isBroswer && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.port)) || "";
        }
        is_object(value) {
            return (typeof value === "object" &&
                Object.prototype.toString.call(value) === "[object Object]");
        }
        is_array(object) {
            return object instanceof Array;
        }
        is_callable(object) {
            return typeof object === "function" && !!object.call;
        }
        is_reserved_option(key) {
            return ReservedOptions.includes(key);
        }
        namespace(object, namespace, routes) {
            const parts = (namespace === null || namespace === void 0 ? void 0 : namespace.split(".")) || [];
            if (parts.length === 0) {
                return;
            }
            for (let index = 0; index < parts.length; index++) {
                const part = parts[index];
                if (index < parts.length - 1) {
                    object = object[part] || (object[part] = {});
                }
                else {
                    object[part] = routes;
                }
            }
        }
        configure(new_config) {
            this.configuration = { ...this.configuration, ...new_config };
            return this.configuration;
        }
        config() {
            return { ...this.configuration };
        }
        is_module_supported(name) {
            return ModuleReferences[name].isSupported();
        }
        ensure_module_supported(name) {
            if (!this.is_module_supported(name)) {
                throw new Error(`${name} is not supported by runtime`);
            }
        }
        define_module(name, module) {
            this.ensure_module_supported(name);
            ModuleReferences[name].define(module);
        }
    }
    const Utils = new UtilsClass();
    // We want this helper name to be short
    const __jsr = {
        r(parts_table, route_spec, absolute) {
            return Utils.route(parts_table, route_spec, absolute);
        },
    };
    const result = {
        ...__jsr,
        configure: (config) => {
            return Utils.configure(config);
        },
        config: () => {
            return Utils.config();
        },
        serialize: (object) => {
            return Utils.serialize(object);
        },
        ...{},
    };
    Utils.define_module("ESM", result);
    return result;
})(this);
export const configure = __jsr.configure;

export const config = __jsr.config;

export const serialize = __jsr.serialize;

/**
 * Generates rails route to
 * /account(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"account"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /account/payment_methods(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_payment_methods_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"account"],[2,[7,"/"],[2,[6,"payment_methods"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /account/profile(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_profile_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"account"],[2,[7,"/"],[2,[6,"profile"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /activate(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const activate_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"activate"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/confirm(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_confirm_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"confirm"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"password"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/password_link(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_password_link_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"password_link"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_search_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/contact_details(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contact_details_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contact_details"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/eu_privacy_consent(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_eu_privacy_consent_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"eu_privacy_consent"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/ping(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_ping_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"ping"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/shipping_addresses(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_shipping_addresses_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"shipping_addresses"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/tax_exemptions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_tax_exemption_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"tax_exemptions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/tax_exemptions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_tax_exemptions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"tax_exemptions"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /arts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const arts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"arts"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /arts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const arts_delete_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"arts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /arts/:id/name(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const arts_rename_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"arts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"name"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /arts/upload(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const arts_upload_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"arts"],[2,[7,"/"],[2,[6,"upload"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/cancel(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancel_user_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /contact_details(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const contact_details_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"contact_details"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/users/count(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const count_api_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"count"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/login_code/create(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_login_code_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"login_code"],[2,[7,"/"],[2,[6,"create"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /designs/:composite_id/hide(.:format)
 * @param {any} composite_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const design_hide_path = __jsr.r({"composite_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"designs"],[2,[7,"/"],[2,[3,"composite_id"],[2,[7,"/"],[2,[6,"hide"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /designs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const designs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"designs"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /users/sign_out(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_out"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/users/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_api_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /oauth/applications/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_oauth_application_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"applications"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/password/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_user_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/login_code/entercode(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const enter_login_code_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"login_code"],[2,[7,"/"],[2,[6,"entercode"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /group_orders(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const group_orders_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"group_orders"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /interactions/date(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const interactions_date_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"interactions"],[2,[7,"/"],[2,[6,"date"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/sign_in_passwordless(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const login_code_sign_in_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in_passwordless"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/users/me(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const me_api_users_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"me"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/users/migrate(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const migrate_api_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"migrate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /oauth/authorize/native(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const native_oauth_authorization_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"authorize"],[2,[7,"/"],[2,[6,"native"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/users/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_api_user_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /oauth/applications/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_oauth_application_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"applications"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/password/new/submit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_submit_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"new"],[2,[7,"/"],[2,[6,"submit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/confirmation/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_confirmation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"confirmation"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/password/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/sign_up(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_up"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/sign_in_password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in_password"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/unlock/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_unlock_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"unlock"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /oauth/applications/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauth_application_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"applications"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /oauth/applications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauth_applications_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"applications"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /oauth/authorize(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauth_authorization_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"authorize"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /oauth/authorized_applications/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauth_authorized_application_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"authorized_applications"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /oauth/authorized_applications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauth_authorized_applications_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"authorized_applications"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /oauth/discovery/keys(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauth_discovery_keys_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"discovery"],[2,[7,"/"],[2,[6,"keys"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /.well-known/openid-configuration(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauth_discovery_provider_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[8,"."],[2,[6,"well-known"],[2,[7,"/"],[2,[6,"openid-configuration"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * /.well-known/webfinger(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauth_discovery_webfinger_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[8,"."],[2,[6,"well-known"],[2,[7,"/"],[2,[6,"webfinger"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * /oauth/introspect(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauth_introspect_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"introspect"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /oauth/revoke(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauth_revoke_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"revoke"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /oauth/token(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauth_token_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"token"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /oauth/token/info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauth_token_info_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"token"],[2,[7,"/"],[2,[6,"info"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /oauth/userinfo(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauth_userinfo_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"userinfo"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /order/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const order_details_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"order"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /orders/store(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const order_stores_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"store"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /orders(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const orders_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"orders"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /rails/info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_info_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"info"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rails/info/properties(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_info_properties_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"info"],[2,[7,"/"],[2,[6,"properties"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/info/routes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_info_routes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"info"],[2,[7,"/"],[2,[6,"routes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/mailers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mailers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"mailers"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /retrieve(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const retrieve_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"retrieve"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /retrieve/fundraisers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const retrieve_fundraisers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"retrieve"],[2,[7,"/"],[2,[6,"fundraisers"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /retrieve/group_orders(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const retrieve_group_orders_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"retrieve"],[2,[7,"/"],[2,[6,"group_orders"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /retrieve/orders(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const retrieve_orders_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"retrieve"],[2,[7,"/"],[2,[6,"orders"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /retrieve/printed_proof(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const retrieve_printed_proof_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"retrieve"],[2,[7,"/"],[2,[6,"printed_proof"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /retrieve/saved_designs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const retrieve_saved_designs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"retrieve"],[2,[7,"/"],[2,[6,"saved_designs"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /retrieve/track_order(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const retrieve_track_order_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"retrieve"],[2,[7,"/"],[2,[6,"track_order"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const root_path = __jsr.r({}, [7,"/"]);

/**
 * Generates rails route to
 * /sb-identity(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sb_identity_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sb-identity"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/users/search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const search_api_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /service_errors(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const service_errors_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"service_errors"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /shipping_addresses(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shipping_addresses_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"shipping_addresses"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /sidekiq
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sidekiq_web_path = __jsr.r({}, [2,[7,"/"],[6,"sidekiq"]]);

/**
 * Generates rails route to
 * /signalman
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const signalman_path = __jsr.r({}, [2,[7,"/"],[6,"signalman"]]);

/**
 * Generates rails route to
 * /signalman/
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const signalman_root_path = __jsr.r({}, [2,[2,[7,"/"],[6,"signalman"]],[7,"/"]]);

/**
 * Generates rails route to
 * /signalman/features(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const signalman_features_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"signalman"]],[7,"/"]],[2,[6,"features"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /signalman/features/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const signalman_feature_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[6,"signalman"]],[7,"/"]],[2,[6,"features"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /signalman/redirects/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const signalman_redirect_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[6,"signalman"]],[7,"/"]],[2,[6,"redirects"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/confirmation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_confirmation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"confirmation"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/auth/facebook(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_facebook_omniauth_authorize_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"facebook"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/auth/facebook/callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_facebook_omniauth_callback_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"facebook"],[2,[7,"/"],[2,[6,"callback"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/auth/google_oauth2(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_google_oauth2_omniauth_authorize_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"google_oauth2"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/auth/google_oauth2/callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_google_oauth2_omniauth_callback_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"google_oauth2"],[2,[7,"/"],[2,[6,"callback"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /users/sign_in_password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in_password"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/unlock(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_unlock_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"unlock"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /users/password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/password/edit_confirmed_embedded(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_password_edit_confirmed_embedded_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"edit_confirmed_embedded"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/password/edit_embedded(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_password_edit_embedded_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"edit_embedded"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_sign_in_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/sign_in_or_sign_up(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_sign_in_or_sign_up_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in_or_sign_up"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/sign_up_confirmation_embedded(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_sign_up_confirmation_embedded_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_up_confirmation_embedded"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/sign_up_embedded(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_sign_up_embedded_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_up_embedded"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/user_exists(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_user_exists_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"user_exists"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/tax_exemptions/valid(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const valid_api_tax_exemptions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"tax_exemptions"],[2,[7,"/"],[2,[6,"valid"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/login_code/validate(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const validate_login_code_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"login_code"],[2,[7,"/"],[2,[6,"validate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /vault/braintree_token(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const vault_braintree_token_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"vault"],[2,[7,"/"],[2,[6,"braintree_token"],[1,[2,[8,"."],[3,"format"]]]]]]]);

