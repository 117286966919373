import { Stack, FormGroup, TextField } from '@customink/pigment-react';
import { useEffect } from 'react';
import { AuthText } from '~/components/AuthText';
import { Centered } from '~/components/Centered';
import { FormLabel } from '~/components/FormLabel';
import { useData } from '~/adapter/browser/useData';
import { new_user_session_path, user_password_path } from '~/adapter/window/routes';
import { usePassToggler } from '~/components/hooks/usePassToggler';
import { AuthContainer } from '~/components/AuthContainer';
import { Link } from '~/components/Link';
import { PasswordToggler } from '~/components/PasswordToggler';
import { CSRFHiddenInput } from '~/components/CSRFHiddenInput';
import { AuthTitle } from '~/components/AuthTitle';
import { FormError } from '~/components/FormError';
import { useSearchParams } from '~/adapter/browser/useSearchParams';
import { AuthButton } from '~/components/AuthButton';

interface ChangePasswordProps {
    resetToken: string;
}

interface ResetPasswordParams {
    reset_password_token: string;
}

export function ResetPassword() {
    const { errors, resetToken } = useData<ChangePasswordProps>();

    const [showPass, toggleShowPass] = usePassToggler();
    const [showPassConfirm, toggleShowPassConfirm] = usePassToggler();
    const {
        // eslint-disable-next-line camelcase
        searchParam: { reset_password_token: queryToken },
        setSearchParam,
    } = useSearchParams<ResetPasswordParams>();
    // We need to add the resetToken to window.location otherwise the server cannot authenticate us on refresh
    // and instead of staying on reset page we will be redirected to login
    useEffect(() => {
        if (!queryToken) {
            setSearchParam('reset_password_token', resetToken, 'no_reset');
        }
    }, []);
    return (
        <AuthContainer>
            <form action={user_password_path()} method="POST">
                <input type="hidden" value={resetToken} name="user[reset_password_token]" />
                <input type="hidden" value="put" name="_method" />
                <Stack gap={3}>
                    <AuthTitle>Reset Password</AuthTitle>
                    <AuthText>Enter new password to your Custom Ink Account.</AuthText>
                    <Stack gap={2}>
                        <FormGroup>
                            <FormLabel error={Boolean(errors.password)}>New password</FormLabel>
                            <TextField
                                error={Boolean(errors.password)}
                                type={showPass ? 'text' : 'password'}
                                name="user[password]"
                                id="user_password"
                                endAdornment={<PasswordToggler show={showPass} toggle={toggleShowPass} />}
                                helperText={<FormError errors={errors.password} />}
                                fullWidth
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormLabel error={Boolean(errors.password)}>Confirm password</FormLabel>
                            <TextField
                                error={Boolean(errors.password_confirmation)}
                                type={showPassConfirm ? 'text' : 'password'}
                                name="user[password_confirmation]"
                                id="user_password"
                                helperText={<FormError errors={errors.password_confirmation} />}
                                endAdornment={<PasswordToggler show={showPassConfirm} toggle={toggleShowPassConfirm} />}
                                fullWidth
                            />
                        </FormGroup>{' '}
                    </Stack>
                    <CSRFHiddenInput />
                    <AuthButton variant="primary" size="medium" type="submit" fullWidth>
                        Continue
                    </AuthButton>
                    <Centered>
                        <Link underline="none" href={new_user_session_path()}>
                            Return to log in page.
                        </Link>
                    </Centered>
                </Stack>
            </form>
        </AuthContainer>
    );
}
