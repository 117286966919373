import { Stack } from '@customink/pigment-react';
import { useData } from '~/adapter/browser/useData';
import { new_user_session_path, user_password_path } from '~/adapter/window/routes';
import { CSRFHiddenInput } from '~/components/CSRFHiddenInput';
import { AuthContainer } from '~/components/AuthContainer';
import { AuthTitle } from '~/components/AuthTitle';
import { AuthText } from '~/components/AuthText';
import { Centered } from '~/components/Centered';
import { trackEvent } from '~/adapter/tracking/trackEvent';
import { AuthButton } from '~/components/AuthButton';
import { Link } from '~/components/Link';

export function ForgetPasswordSubmit() {
    const { email } = useData();

    return (
        <AuthContainer>
            <Stack gap={3}>
                <Stack gap={2}>
                    <AuthTitle>Reset Password Link Sent</AuthTitle>
                    <AuthText>
                        If there's an account for {email}, we will send an email with instructions to reset your
                        password in the next few minutes.
                    </AuthText>
                </Stack>
                <form action={user_password_path()} method="POST">
                    <Stack gap={4}>
                        <CSRFHiddenInput />
                        <input type="hidden" name="user[email]" value={email} />
                        <AuthButton
                            variant="primary"
                            size="medium"
                            type="submit"
                            onClick={trackEvent('Reset Password Again')}
                            fullWidth
                        >
                            Resend Link
                        </AuthButton>
                        <Centered>
                            <Link
                                href={new_user_session_path()}
                                underline="none"
                                onClick={trackEvent('Return to Log In')}
                            >
                                Return to log in page.
                            </Link>
                        </Centered>
                    </Stack>
                </form>
            </Stack>
        </AuthContainer>
    );
}
