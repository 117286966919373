import { PropsWithChildren } from 'react';
import { Box, PigmentThemeProvider } from '@customink/pigment-react';
import '@customink/pigment-react/lib/fonts.css';
import { styled } from '@mui/material';
import { GaPageReporter } from '~/components/GaPageReporter';
import { BrowserHistoryRouter } from './components/CustomRouter';

const FontWrapperBox = styled(Box)(({ theme }) => ({
    fontFamily: theme.typography.fontFamily,
}));

export function AppContext({ children }: PropsWithChildren<unknown>) {
    return (
        <PigmentThemeProvider>
            <FontWrapperBox>
                <BrowserHistoryRouter>
                    <GaPageReporter>{children}</GaPageReporter>
                </BrowserHistoryRouter>
            </FontWrapperBox>
        </PigmentThemeProvider>
    );
}
