import { Alert, Stack } from '@customink/pigment-react';
import { useData } from '~/adapter/browser/useData';
import { AuthContainer } from '~/components/AuthContainer';
import { AuthTitle } from '~/components/AuthTitle';
import { AuthText } from '~/components/AuthText';
import { trackEvent } from '~/adapter/tracking/trackEvent';
import { UpperCaseChip } from '~/components/UpperCaseChip';
import { useActivate } from '~/adapter/api/useActivate';
import { Flash } from '~/components/Flash';
import { useApiTimeout } from '~/adapter/api/useApiTimeout';
import { AuthButton } from '~/components/AuthButton';

export function VerificationPage() {
    const { email } = useData();
    const { handler: activate, loading } = useApiTimeout(useActivate(), 60);

    return (
        <AuthContainer>
            <Stack gap={3}>
                {loading && <Flash type="notice" message="Verification email resent, please check your inbox." />}
                <div>
                    <UpperCaseChip label="one last step" />
                </div>
                <AuthTitle>Verify Your Account</AuthTitle>
                <Stack gap={2}>
                    <AuthText>We sent you an email with easy, one-click instructions to verify your account.</AuthText>
                    <AuthText>Didn’t receive the email? Click below to resend it.</AuthText>
                </Stack>
                <input type="hidden" value={email} name="user[email]" />
                <AuthButton
                    variant="primary"
                    size="medium"
                    type="submit"
                    onClick={() => {
                        trackEvent('Resend verification');
                        activate();
                    }}
                    disabled={loading}
                    fullWidth
                >
                    Resend Verification Email
                </AuthButton>

                <Alert severity="info">
                    Email verification helps us keep your account secure. You only need to do it once.
                </Alert>
            </Stack>
        </AuthContainer>
    );
}
