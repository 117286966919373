import { IconData } from './IconData';

export function Google({ width, height }: IconData) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 19 19" fill="none">
            <mask
                id="mask0_164_6374"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="9"
                y="7"
                width="10"
                height="10"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.7348 9.39213C18.7348 8.74096 18.674 8.11475 18.561 7.51367H9.55859V11.0661H14.7028C14.4812 12.214 13.8078 13.1867 12.7955 13.8379V16.1422H15.8846C17.6921 14.5433 18.7348 12.189 18.7348 9.39213Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_164_6374)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.55859 16.1422H18.7348V7.51367H9.55859V16.1422Z"
                    fill="#0082FC"
                />
            </g>
            <mask
                id="mask1_164_6374"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="1"
                y="10"
                width="15"
                height="9"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.55842 18.3666C12.1392 18.3666 14.303 17.5442 15.8844 16.1416L12.7953 13.8373C11.9394 14.3884 10.8445 14.714 9.55842 14.714C7.06886 14.714 4.9616 13.0985 4.21002 10.9277H1.0166V13.3072C2.58942 16.3086 5.82187 18.3666 9.55842 18.3666Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask1_164_6374)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.0166 18.3666H15.8844V10.9277H1.0166V18.3666Z"
                    fill="#00A83D"
                />
            </g>
            <mask
                id="mask2_164_6374"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="5"
                width="5"
                height="9"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.2101 10.9277C4.01889 10.3767 3.91026 9.78815 3.91026 9.18284C3.91026 8.57758 4.01889 7.98898 4.2101 7.43794V5.05859H1.01668C0.369322 6.29836 0 7.70095 0 9.18284C0 10.6648 0.369322 12.0674 1.01668 13.3072L4.2101 10.9277Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask2_164_6374)">
                <path fillRule="evenodd" clipRule="evenodd" d="M0 13.3072H4.2101V5.05859H0V13.3072Z" fill="#FFCA26" />
            </g>
            <mask
                id="mask3_164_6374"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="1"
                y="0"
                width="15"
                height="8"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.55842 3.6526C10.9618 3.6526 12.2218 4.11599 13.2124 5.02599L15.954 2.39194C14.2986 0.910055 12.1349 0 9.55842 0C5.82187 0 2.58942 2.05799 1.0166 5.05942L4.21002 7.43877C4.9616 5.2681 7.06886 3.6526 9.55842 3.6526Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask3_164_6374)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.0166 7.43877H15.954V0H1.0166V7.43877Z"
                    fill="#DB4437"
                />
            </g>
        </svg>
    );
}
