import { ButtonProps, styled } from '@mui/material';
import { Button, Typography } from '@customink/pigment-react';
import { PropsWithChildren } from 'react';

const BrandStyledButton = styled(Button)(({ theme }) => ({
    padding: theme.spacing(1),
}));

const ColoredBrandStyledButton = styled(BrandStyledButton)(({ theme }) => ({
    path: {
        fill: theme.palette.secondary.main,
        transition: theme.transitions.create('fill', {
            duration: theme.transitions.duration.short,
            easing: theme.transitions.easing.easeInOut,
        }),
    },
    ':hover path': {
        fill: theme.palette.background.default,
    },
}));

const BrandButtonStyledText = styled(Typography)({
    display: 'flex',
    textTransform: 'capitalize',
    alignItems: 'center',
});

function BrandButtonText({ children }: PropsWithChildren<unknown>) {
    return <BrandButtonStyledText variant="bodyLong2">{children}</BrandButtonStyledText>;
}

export function BrandButton({ children, onClick }: PropsWithChildren<ButtonProps>) {
    return (
        <BrandStyledButton variant="secondary" fullWidth onClick={onClick}>
            <BrandButtonText>{children}</BrandButtonText>
        </BrandStyledButton>
    );
}

export function ColoredBrandButton({ children, onClick }: PropsWithChildren<ButtonProps>) {
    return (
        <ColoredBrandStyledButton variant="secondary" fullWidth onClick={onClick}>
            <BrandButtonText>{children}</BrandButtonText>
        </ColoredBrandStyledButton>
    );
}
