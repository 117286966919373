import { Button, ButtonProps, styled } from '@mui/material';
import { ArrowForwardIos as ArrowForward } from '@mui/icons-material';
import { PWCH } from '~/adapter/react/type';
import { AuthText } from './AuthText';

const StyledArrowButton = styled(Button)(({ theme }) => ({
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    textDecoration: 'none',
}));
export function ArrowedButton({ children, ...props }: PWCH<ButtonProps>) {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <StyledArrowButton color="secondary" variant="text" {...props} fullWidth>
            <AuthText>
                <span>{children}</span>
            </AuthText>
            <ArrowForward fontSize="small" />
        </StyledArrowButton>
    );
}
