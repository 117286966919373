import { PropsWithChildren } from 'react';
import { Typography } from '@customink/pigment-react';
import { styled } from '@mui/material';

const StyledTitle = styled(Typography)({
    fontWeight: 'bold',
    padding: 0,
});

export function AuthTitle({ children }: PropsWithChildren<unknown>) {
    return <StyledTitle variant="h4">{children}</StyledTitle>;
}
