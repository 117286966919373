import { useEffect, useState } from 'react';
import { Link as MuiLink } from '@mui/material';
import { Checkbox, FormControlLabel, FormGroup, Grid, Stack, TextField, Typography } from '@customink/pigment-react';
import { useData } from '~/adapter/browser/useData';
import { user_registration_path, users_sign_in_path } from '~/adapter/window/routes';
import { CSRFHiddenInput } from '~/components/CSRFHiddenInput';
import { FlashType, User, RailsError } from '~/adapter/window/data';
import { Flash } from '~/components/Flash';
import { AuthContainer } from '~/components/AuthContainer';
import { AuthTitle } from '~/components/AuthTitle';
import { AuthText } from '~/components/AuthText';
import { Centered } from '~/components/Centered';
import { usePassToggler } from '~/components/hooks/usePassToggler';
import { PasswordToggler } from '~/components/PasswordToggler';
import { LoginAlternatives } from '~/components/LoginAlternatives';
import { FormLabel } from '~/components/FormLabel';
import { Link } from '~/components/Link';
import { FormError } from '~/components/FormError';
import { trackEvent } from '~/adapter/tracking/trackEvent';
import { trackSignupFail, trackSignupSubmit } from '~/adapter/tracking/loginAndSignup';
import { trackPage } from '~/adapter/tracking/trackPage';
import { useOneTimeDefaultEmail } from '~/components/hooks/useDefaultEmail';
import { OrDivider } from '~/components/OrDivider';
import { AuthButton } from '~/components/AuthButton';

const cypressIdEmail = 'user_email';
const cypressIdPass = 'user_password';
const cypressIdPassConfirm = 'user_password_confirmation';

function trackErrorsToGA(pageTag: string, userErrors: RailsError<User>, flash?: FlashType) {
    const errors = [];
    if (flash?.type === 'error') {
        errors.push(flash?.message);
    }
    if (userErrors?.password) {
        trackPage(`${pageTag}/email_password_error`);
        errors.push(userErrors?.password.join(', '));
    }
    if (userErrors?.password_confirmation) {
        trackPage(`${pageTag}/password_confirmation_error`);
        errors.push(userErrors?.password_confirmation.join(', '));
    }
    if (userErrors?.email) {
        trackPage(`${pageTag}/email_password_error`);
        errors.push(userErrors?.email.join(', '));
    }
    if (errors.length > 0) {
        trackSignupFail('Onsite', errors.join(','));
    }
}

export function SignUp() {
    const { flash, email, origin, errors, euRequest } = useData();

    useEffect(() => {
        // This is unfortunately the only way to track login errors correctly
        // Because we don't have access to all errors at single location
        trackErrorsToGA('/accounts/login/create/password', errors, flash);
    }, [errors, flash]);

    const [showPass, setShowPass] = usePassToggler();
    const [showPassConfirm, setShowPassConfirm] = usePassToggler();
    const [consent, setConsent] = useState(!euRequest);

    const defaultEmail = useOneTimeDefaultEmail(email);
    const [userEmail, setUserEmail] = useState(defaultEmail);

    return (
        <AuthContainer>
            <Stack gap={4}>
                <Stack gap={3}>
                    <Stack gap={2}>
                        <AuthTitle>Sign Up</AuthTitle>

                        <AuthText>Secure your account by creating a password and verifying your email.</AuthText>
                    </Stack>
                    <form id="signup_form" action={user_registration_path()} method="POST">
                        <Stack gap={3}>
                            <Stack gap={2}>
                                <FormGroup>
                                    <FormLabel error={Boolean(errors.email)}>Enter email address</FormLabel>
                                    <TextField
                                        error={Boolean(errors.email)}
                                        autoFocus
                                        type="email"
                                        name="user[email]"
                                        id={cypressIdEmail}
                                        helperText={<FormError errors={errors.email} id={cypressIdEmail} />}
                                        defaultValue={userEmail}
                                        onChange={(ev) => setUserEmail(ev.target.value)}
                                        required
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel error={Boolean(errors.password)}>Enter new password</FormLabel>
                                    <TextField
                                        error={Boolean(errors.password)}
                                        type={showPass ? 'text' : 'password'}
                                        name="user[password]"
                                        id={cypressIdPass}
                                        endAdornment={<PasswordToggler show={showPass} toggle={setShowPass} />}
                                        helperText={<FormError errors={errors.password} id={cypressIdPass} />}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel error={Boolean(errors.password)}>Confirm new password</FormLabel>
                                    <TextField
                                        error={Boolean(errors.password_confirmation)}
                                        type={showPassConfirm ? 'text' : 'password'}
                                        name="user[password_confirmation]"
                                        id={cypressIdPassConfirm}
                                        helperText={
                                            <FormError
                                                errors={errors.password_confirmation}
                                                id={cypressIdPassConfirm}
                                            />
                                        }
                                        endAdornment={
                                            <PasswordToggler show={showPassConfirm} toggle={setShowPassConfirm} />
                                        }
                                    />
                                </FormGroup>{' '}
                            </Stack>
                            <CSRFHiddenInput />
                            <input type="hidden" id="user_origin" name="origin" value={origin} />
                            {flash && <Flash type={flash.type} message={flash.message} />}
                            {euRequest && (
                                <Grid container>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="secondary"
                                                    size="medium"
                                                    value={consent}
                                                    name="eu_consent"
                                                    onChange={(_ev, newVal) => setConsent(newVal)}
                                                />
                                            }
                                            label={
                                                <Typography variant="bodyShort2">
                                                    I have read and accepted the{' '}
                                                    <MuiLink
                                                        href="https://www.customink.com/about/privacy"
                                                        target="_blank"
                                                    >
                                                        Privacy Policy
                                                    </MuiLink>
                                                </Typography>
                                            }
                                        />
                                    </FormGroup>
                                </Grid>
                            )}
                            <AuthText>
                                By clicking Continue, I agree to the{' '}
                                <MuiLink href="https://www.customink.com/about/user_agreement" target="_blank">
                                    Terms of Service
                                </MuiLink>
                                {' and '}
                                <MuiLink href="https://www.customink.com/about/privacy" target="_blank">
                                    Privacy Policy
                                </MuiLink>
                                .
                            </AuthText>
                            <AuthButton
                                id="signup_submit"
                                variant="primary"
                                size="medium"
                                type="submit"
                                disabled={!consent}
                                onClick={(e) => {
                                    trackEvent('Sign Up')(e);
                                    trackSignupSubmit('Onsite');
                                }}
                                fullWidth
                            >
                                Continue
                            </AuthButton>
                        </Stack>
                    </form>
                </Stack>

                <OrDivider />
                <LoginAlternatives
                    euRequest={euRequest}
                    userEmail={userEmail}
                    enablePasswordLogin={false}
                    enablePasswordlessLogin={false}
                />
                <>
                    {userEmail ? (
                        <Centered>
                            <Link underline="none" href={users_sign_in_path()} onClick={trackEvent('Different Email')}>
                                Log in using a different email
                            </Link>
                        </Centered>
                    ) : (
                        <Centered>
                            Already have an account?{' '}
                            <Link underline="none" href={users_sign_in_path()} onClick={trackEvent('Log In')}>
                                Log in.
                            </Link>
                        </Centered>
                    )}
                </>
            </Stack>
        </AuthContainer>
    );
}
