import { PWCH } from '~/adapter/react/type';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { trackPage } from '~/adapter/tracking/trackPage';
import { useProfilesGa } from '~/adapter/tracking/auth0ga';

export function GaPageReporter({ children }: PWCH) {
    const location = useLocation();

    useProfilesGa();
    useEffect(() => {
        trackPage(location.pathname);
    }, [location]);

    return <>{children}</>;
}
