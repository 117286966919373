import { useEffect, useMemo } from 'react';

const sessionStorageEmailKey = 'profiles_default_email';

export function useOneTimeDefaultEmail(backendValue = '') {
    const sessionStorageValue = useMemo(() => {
        return sessionStorage.getItem(sessionStorageEmailKey) ?? '';
    }, []);

    // This is a one-time read, so we can remove it after the first read for PII reasons
    useEffect(() => {
        setTimeout(() => {
            sessionStorage.removeItem(sessionStorageEmailKey);
        }, 100);
    }, []);

    return backendValue || sessionStorageValue;
}

export function persistDefaultEmail(email?: string) {
    sessionStorage.setItem(sessionStorageEmailKey, email ?? '');
}
