import { useCallback, useEffect } from 'react';
import { FormGroup, Stack, TextField, Typography } from '@customink/pigment-react';
import { FormLabel } from '~/components/FormLabel';
import { new_user_password_path, users_password_edit_embedded_path } from '~/adapter/window/routes';
import { PasswordToggler } from '~/components/PasswordToggler';
import { CSRFHiddenInput } from '~/components/CSRFHiddenInput';
import { useData } from '~/adapter/browser/useData';
import { usePassToggler } from '~/components/hooks/usePassToggler';
import { useMeasureDivElement } from '~/adapter/browser/useMeasureDivElement';
import { FormError } from '~/components/FormError';
import { Link } from '~/components/Link';
import { AuthButton } from '~/components/AuthButton';
import { persistDefaultEmail } from '~/components/hooks/useDefaultEmail';

const cypressIdCurrentPass = 'current_password';
const cypressIdNewPass = 'user_password';
const cypressIdNewPassConfirm = 'user_password_confirmation';

export function EditPasswordEmbeddedPage() {
    // eslint-disable-next-line camelcase
    const { skip_password_required, email, errors } = useData();
    const [showPass, toggleShowPass] = usePassToggler();
    const [showPassConfirm, toggleShowPassConfirm] = usePassToggler();
    const { ref, size } = useMeasureDivElement();
    useEffect(() => {
        if (size) {
            window.parent.postMessage(
                {
                    type: 'profiles.password-size',
                    width: size.width,
                    height: size.height,
                },
                '*',
            );
        }
    }, [size]);

    const close = useCallback(() => {
        window.parent.postMessage('profiles.password-cancel');
    }, []);
    return (
        <div ref={ref}>
            <form id="edit_pass_form" action={users_password_edit_embedded_path()} method="POST">
                <Stack gap={3}>
                    <Stack gap={2}>
                        {/* eslint-disable-next-line camelcase */}
                        {!skip_password_required && (
                            <Stack gap={1}>
                                <FormGroup>
                                    <FormLabel error={Boolean(errors.current_password)}>
                                        Enter your current password
                                    </FormLabel>
                                    <TextField
                                        error={Boolean(errors.current_password)}
                                        autoFocus
                                        type="password"
                                        name="user[current_password]"
                                        id={cypressIdCurrentPass}
                                        helperText={
                                            <FormError errors={errors.current_password} id={cypressIdCurrentPass} />
                                        }
                                    />
                                </FormGroup>
                                <Typography variant="bodyShort1">
                                    Don't remember your password?{' '}
                                    <Link
                                        onClick={() => persistDefaultEmail(email)}
                                        href={new_user_password_path()}
                                        target="_blank"
                                    >
                                        Reset it now
                                    </Link>
                                </Typography>
                            </Stack>
                        )}
                        <FormGroup>
                            <FormLabel error={Boolean(errors.password)}>Enter your new password</FormLabel>
                            <TextField
                                error={Boolean(errors.password)}
                                // eslint-disable-next-line camelcase
                                autoFocus={skip_password_required}
                                type={showPass ? 'text' : 'password'}
                                name="user[password]"
                                id={cypressIdNewPass}
                                endAdornment={<PasswordToggler show={showPass} toggle={toggleShowPass} />}
                                helperText={<FormError errors={errors.password} id={cypressIdNewPass} />}
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormLabel error={Boolean(errors.password)}>Confirm your new password</FormLabel>
                            <TextField
                                error={Boolean(errors.password_confirmation)}
                                type={showPassConfirm ? 'text' : 'password'}
                                name="user[password_confirmation]"
                                id={cypressIdNewPassConfirm}
                                helperText={
                                    <FormError errors={errors.password_confirmation} id={cypressIdNewPassConfirm} />
                                }
                                endAdornment={<PasswordToggler show={showPassConfirm} toggle={toggleShowPassConfirm} />}
                            />
                        </FormGroup>{' '}
                    </Stack>
                    <CSRFHiddenInput />
                    <AuthButton id="edit_submit" variant="primary" size="medium" type="submit" fullWidth>
                        Save
                    </AuthButton>
                    <AuthButton id="edit_cancel" variant="secondary" size="medium" onClick={close} fullWidth>
                        Cancel
                    </AuthButton>
                </Stack>
            </form>
        </div>
    );
}
