import Cookies from 'cookies-ts';
import { trackLoginFail, trackLoginSuccess, trackSignupFail, trackSignupSuccess } from './loginAndSignup';

const cookies = new Cookies();

// The reason short property names is because our cookies are already super large and bloated
// t: type, u: userId, m: method, s: state
interface TrackCookie {
    t: number;
    u: string;
    m: 'Onsite' | 'Google' | 'Facebook';
    s: boolean;
}

const PROFILES_TRACKING_COOKIE = '_p_tc_';

export function trackLogin() {
    const cookie = cookies.get(PROFILES_TRACKING_COOKIE) as unknown as TrackCookie;
    if (cookie?.t === 1 && cookie?.s) {
        trackLoginSuccess(cookie.m, cookie.u);
        cookies.remove(PROFILES_TRACKING_COOKIE);
    }

    if (cookie?.t === 1 && !cookie?.s) {
        trackLoginFail(cookie.m, cookie.u);
        cookies.remove(PROFILES_TRACKING_COOKIE);
    }

    if (cookie?.t === 2 && cookie?.s) {
        trackSignupSuccess(cookie.m, cookie.u);
        cookies.remove(PROFILES_TRACKING_COOKIE);
    }

    if (cookie?.t === 2 && !cookie?.s) {
        trackSignupFail(cookie.m, cookie.u);
        cookies.remove(PROFILES_TRACKING_COOKIE);
    }
}
