import { useLocation, useNavigate } from 'react-router-dom';
import { useMemo } from 'react';

export function useSearchParams<T extends Record<string, any>>(): {
    searchParam: T;
    setSearchParam: (key: string, value: string, state: any) => void;
} {
    const location = useLocation();
    const search = useMemo(() => new URLSearchParams(location.search), [location]);
    const searchAsObject = Object.fromEntries(new URLSearchParams(search));
    const navigate = useNavigate();

    function setSearchParam(key: string, value: string, state = null) {
        search.set(key, value);
        navigate(`?${search.toString()}`, { state });
    }

    return { searchParam: searchAsObject as T, setSearchParam };
}
