import { Alert } from '@customink/pigment-react';
import { AlertColor } from '@mui/material';
import { useMemo } from 'react';

type FlashType = 'error' | 'notice';

interface FlashProps {
    type: FlashType;
    message: string;
}

function noticeTypeToSeverity(type: FlashType): AlertColor {
    switch (type) {
        case 'error':
            return 'error';
        case 'notice':
            return 'success';
        default:
            return 'info';
    }
}

export function Flash({ message, type }: FlashProps) {
    const severity = useMemo(() => noticeTypeToSeverity(type), [type]);
    return (
        <>
            <Alert severity={severity}>
                <span dangerouslySetInnerHTML={{ __html: message }} />
            </Alert>
        </>
    );
}
