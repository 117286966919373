import { PageData } from '~/adapter/window/data';

declare global {
    // eslint-disable-next-line no-var,vars-on-top
    var __nautilus_profiles: Record<string, any>;
}

export function useData<T>(): PageData<T> {
    const data = window.__nautilus_profiles?.page_data;
    if (!data) {
        throw new Error("Requested data couldn't be found on the window");
    }
    return data;
}
