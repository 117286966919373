import { useEffect, useRef, useState } from 'react';

export function useMeasureDivElement() {
    const elementRef = useRef<HTMLDivElement>(null);
    const [size, setSize] = useState<DOMRect | undefined>();

    useEffect(() => {
        if (elementRef.current) {
            const domRect = elementRef.current.getBoundingClientRect();
            setSize(domRect);
        }
    }, [elementRef]);

    return {
        ref: elementRef,
        size,
    };
}
