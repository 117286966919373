import { FormGroup, Stack, TextField } from '@customink/pigment-react';
import { ChangeEvent, ClipboardEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import { validate_login_code_path } from '~/adapter/window/routes';
import { FormLabel } from '~/components/FormLabel';
import { trackEvent } from '~/adapter/tracking/trackEvent';
import { ButtonLoader } from '~/components/ButtonLoader';
import { Flash } from '~/components/Flash';
import { useData } from '~/adapter/browser/useData';
import { CSRFHiddenInput } from '~/components/CSRFHiddenInput';
import { AuthButton } from '~/components/AuthButton';

function inputCleaner(input: string) {
    return input.replace(/\W/g, '').slice(0, 6);
}

export function PasswordLessCode() {
    const { flash } = useData();
    const [value, setValue] = useState('');
    const [loading, setLoading] = useState(false);
    const formRef = useRef<HTMLFormElement | null>(null);
    const onPaste: ClipboardEventHandler<HTMLFormElement> = useCallback((e) => {
        e.preventDefault();
        const data = e.clipboardData.getData('text');
        // remove non alpha numeric characters
        setValue(inputCleaner(data));
    }, []);

    const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setValue(inputCleaner(e.target.value));
    }, []);
    useEffect(() => {
        if (formRef.current && value.length === 6) {
            setLoading(true);
            setTimeout(() => {
                formRef.current?.submit();
            }, 300);
        }
    }, [value]);
    return (
        <form action={validate_login_code_path()} method="POST" onPaste={onPaste} ref={formRef} id="code_submit_form">
            <CSRFHiddenInput />
            <Stack gap={3}>
                <FormGroup>
                    <FormLabel>Enter verification code</FormLabel>
                    <TextField
                        autoFocus
                        name="code"
                        id="passwordless-code"
                        value={value}
                        onChange={onChange}
                        removeHelperTextSpace
                        required
                    />
                </FormGroup>
                <AuthButton
                    id="code_submit"
                    variant="primary"
                    size="medium"
                    type="submit"
                    fullWidth
                    onClick={trackEvent('Verify Code')}
                    disabled={loading || !value.length}
                >
                    {loading && <ButtonLoader />}
                    Continue
                </AuthButton>
                {flash && <Flash type={flash.type} message={flash.message} />}
            </Stack>
        </form>
    );
}
