import { FormLabel as MuiFormLabel, FormLabelProps } from '@mui/material';
import { Typography } from '@customink/pigment-react';

export function FormLabel({ children, ...rest }: FormLabelProps) {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <MuiFormLabel {...rest}>
            <Typography variant="captionRegular" sx={{ fontWeight: 600, textTransform: 'capitalize' }}>
                {children}
            </Typography>
        </MuiFormLabel>
    );
}
