import { createContext, PropsWithChildren, useContext, useLayoutEffect, useRef, useState } from 'react';
import { BrowserHistory, createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';

export const HistoryContext = createContext<BrowserHistory | null>(null);

export function useHistory() {
    const history = useContext(HistoryContext);
    if (!history) {
        throw new Error('Have to be specified inside history context.');
    }

    return history;
}

// Router based on https://github.com/remix-run/react-router/blob/main/packages/react-router-dom/index.tsx
// Custom implementation is needed because we need access to history object
export function BrowserHistoryRouter({ children }: PropsWithChildren<unknown>) {
    const historyRef = useRef<BrowserHistory>();
    if (historyRef.current == null) {
        historyRef.current = createBrowserHistory({ window });
    }

    const history = historyRef.current;
    const [state, setState] = useState({
        action: history.action,
        location: history.location,
    });

    useLayoutEffect(() => history.listen(setState), [history]);

    return (
        <Router location={state.location} navigationType={state.action} navigator={history}>
            <HistoryContext.Provider value={history}>{children}</HistoryContext.Provider>
        </Router>
    );
}
