import { IconData } from './IconData';

export function PasswordLock({ width, height }: IconData) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 17 18" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.0753 10.1799C10.5095 10.1799 10.8615 10.5292 10.8615 10.9669C10.8615 11.4016 10.508 11.754 10.0753 11.754H9.28744V12.541H10.0753C10.5095 12.541 10.8615 12.8903 10.8615 13.328C10.8615 13.7627 10.508 14.1151 10.0753 14.1151H8.50041C8.06574 14.1151 7.71337 13.7635 7.71337 13.3312V8.86499C6.79632 8.54086 6.1393 7.66628 6.1393 6.63823C6.1393 5.33423 7.1964 4.27712 8.50041 4.27712C9.80441 4.27712 10.8615 5.33423 10.8615 6.63823C10.8615 7.66628 10.2045 8.54086 9.28744 8.86499V10.1799H10.0753ZM16.3706 8.99928C16.3706 13.346 12.8469 16.8696 8.50025 16.8696C4.15357 16.8696 0.629883 13.346 0.629883 8.99928C0.629883 4.65259 4.15357 1.12891 8.50025 1.12891C12.8469 1.12891 16.3706 4.65259 16.3706 8.99928ZM2.20421 8.9993C2.20421 12.4766 5.02315 15.2956 8.5005 15.2956C11.9779 15.2956 14.7968 12.4766 14.7968 8.9993C14.7968 5.52195 11.9779 2.703 8.5005 2.703C5.02315 2.703 2.20421 5.52195 2.20421 8.9993ZM9.28735 6.63828C9.28735 6.20361 8.93498 5.85124 8.50031 5.85124C8.06564 5.85124 7.71327 6.20361 7.71327 6.63828C7.71327 7.07295 8.06564 7.42532 8.50031 7.42532C8.93498 7.42532 9.28735 7.07295 9.28735 6.63828Z"
            />
        </svg>
    );
}
