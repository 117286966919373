import { Container, styled } from '@mui/material';
import { forwardRef, PropsWithChildren } from 'react';

const Width = 517;

const StyledContainer = styled(Container)(({ theme }) => ({
    width: Width,
    padding: 0,
    marginTop: '40px',
    [theme.breakpoints.up('md')]: {
        marginTop: '120px',
    },
    [theme.breakpoints.down('sm')]: {
        width: '100%',
    },
}));

export const AuthContainer = forwardRef<HTMLDivElement, PropsWithChildren<unknown>>(({ children }, ref) => (
    <StyledContainer ref={ref}>{children}</StyledContainer>
));
