import { useCallback, useState } from 'react';
import { EmptyFunction } from '~/adapter/javascript/javascript';

export function usePassToggler(defaultState = false): [boolean, EmptyFunction] {
    const [show, setShow] = useState(defaultState);

    const toggle = useCallback(function toggle() {
        setShow((s) => !s);
    }, []);

    return [show, toggle];
}
