import { useCallback } from 'react';
import { activate_path } from '~/adapter/window/routes';

export function useActivate() {
    return useCallback(() => {
        return fetch(activate_path(), {
            method: 'GET',
        });
    }, []);
}
