import { useCallback, useEffect, useState } from 'react';

export function useApiTimeout<P extends any[], R>(
    fnc: (...params: P) => Promise<R>,
    timeInSecs: number,
): { handler: (...params: P) => Promise<R>; loading: boolean } {
    const [loading, setLoading] = useState(false);
    const [timerRun, setTimerRun] = useState(0);
    const startTimer = useCallback(() => {
        setLoading(true);
        setTimerRun((tr) => tr + 1);
    }, []);

    useEffect(() => {
        if (timerRun === 0) {
            return undefined;
        }
        const timeoutId = setTimeout(() => {
            setLoading(false);
        }, timeInSecs * 1000);
        return () => clearTimeout(timeoutId);
    }, [timerRun]);

    const handler = useCallback(
        async (...params: P): Promise<R> => {
            startTimer();
            return fnc(...params);
        },
        [fnc],
    );

    return {
        handler,
        loading,
    };
}
