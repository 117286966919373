import { useEffect } from 'react';
import { useHistory } from './CustomRouter';

export function LocationChangeDataCleaner() {
    const history = useHistory();
    useEffect(() => {
        return history.listen((change) => {
            if (change.location.state === 'no_reset') {
                return;
            }
            window.__nautilus_profiles.page_data.flash = null;
            window.__nautilus_profiles.page_data.email = '';
            window.__nautilus_profiles.page_data.errors = {};
        });
    }, [history]);

    return null;
}
