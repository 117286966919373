import React from 'react';
import { getUserId } from './userId';

function isInput(el: Element): el is HTMLInputElement {
    return el instanceof HTMLInputElement;
}

function getActionType(el: Element | HTMLInputElement) {
    const tagName = el.tagName.toUpperCase();
    let label = tagName;
    if (tagName === 'A') {
        label = 'Link';
    } else if (tagName === 'INPUT' && isInput(el) && ['button', 'submit'].includes(el.type.toLowerCase())) {
        label = 'Button';
    }

    label = label.toLowerCase().replace(/(?:^|\s)\S/g, function toUpperCase(a) {
        return a.toUpperCase();
    });
    return `${label} Click`;
}

export function trackLinksAndButtons(label: string, e: React.UIEvent) {
    ga('send', {
        hitType: 'event',
        eventCategory: 'Account',
        eventAction: getActionType(e.currentTarget),
        eventLabel: label,
        nonInteraction: false,
        dimension118: getUserId(),
    });
}
