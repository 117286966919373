import { FormGroup, Stack, TextField } from '@customink/pigment-react';
import { useData } from '~/adapter/browser/useData';
import { new_user_session_path, user_password_path } from '~/adapter/window/routes';
import { CSRFHiddenInput } from '~/components/CSRFHiddenInput';
import { AuthContainer } from '~/components/AuthContainer';
import { Flash } from '~/components/Flash';
import { AuthTitle } from '~/components/AuthTitle';
import { AuthText } from '~/components/AuthText';
import { Centered } from '~/components/Centered';
import { FormLabel } from '~/components/FormLabel';
import { trackEvent } from '~/adapter/tracking/trackEvent';
import { Link } from '~/components/Link';
import { useOneTimeDefaultEmail } from '~/components/hooks/useDefaultEmail';
import { AuthButton } from '~/components/AuthButton';

export function ForgetPassword() {
    const { flash, errors, email } = useData();
    const defaultEmail = useOneTimeDefaultEmail(email);

    return (
        <AuthContainer>
            <Stack gap={3}>
                {flash && <Flash type={flash.type} message={flash.message} />}
                <AuthTitle>Forgot Password?</AuthTitle>
                <AuthText>We'll email you a link to help reset your password.</AuthText>
                <form action={user_password_path()} method="POST">
                    <Stack gap={3}>
                        <FormGroup>
                            <FormLabel>Enter email address</FormLabel>
                            <TextField
                                autoFocus
                                type="email"
                                name="user[email]"
                                id="user_email"
                                defaultValue={defaultEmail}
                                removeHelperTextSpace
                                required
                            />
                        </FormGroup>
                        <CSRFHiddenInput />
                        {errors.email && <Flash type="error" message={errors.email[0]} />}
                        <AuthButton
                            variant="primary"
                            size="medium"
                            type="submit"
                            fullWidth
                            onClick={trackEvent('Reset Password')}
                        >
                            Continue
                        </AuthButton>
                        <Centered>
                            <Link
                                href={new_user_session_path()}
                                underline="none"
                                onClick={trackEvent('Return to Log In')}
                            >
                                Return to log in page.
                            </Link>
                        </Centered>
                    </Stack>
                </form>
            </Stack>
        </AuthContainer>
    );
}
