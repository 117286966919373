import { InputAdornment } from '@mui/material';
import { Typography } from '@customink/pigment-react';
import { EmptyFunction } from '~/adapter/javascript/javascript';

interface PasswordTogglerProps {
    toggle: EmptyFunction;
    show: boolean;
}
export function PasswordToggler({ show, toggle }: PasswordTogglerProps) {
    return (
        <InputAdornment position="end" onClick={toggle} sx={{ cursor: 'pointer' }}>
            <Typography variant="captionRegular">{show ? 'HIDE' : 'SHOW'}</Typography>
        </InputAdornment>
    );
}
