import { Stack } from '@customink/pigment-react';
import {
    login_code_sign_in_path,
    new_user_session_path,
    user_google_oauth2_omniauth_authorize_path,
} from '~/adapter/window/routes';
import { trackEvent } from '~/adapter/tracking/trackEvent';
import { trackLoginSubmit } from '~/adapter/tracking/loginAndSignup';
import { useMemo } from 'react';
import { persistDefaultEmail } from '~/components/hooks/useDefaultEmail';
import { BrandButton, ColoredBrandButton } from './BrandButton';
import { Google } from './icons/Google';
import { Link } from './Link';
import { MagicWand } from './icons/MagicWand';
import { PasswordLock } from './icons/PasswordLock';

interface OAuthLoginProps {
    euRequest: boolean;
    enablePasswordLogin?: boolean;
    enablePasswordlessLogin?: boolean;
    enableSocialLogin?: boolean;
    userEmail?: string;
}

export function LoginAlternatives({
    userEmail,
    euRequest,
    enablePasswordLogin = true,
    enablePasswordlessLogin = true,
    enableSocialLogin = true,
}: OAuthLoginProps) {
    const enableSocialLoginInEU = useMemo(
        () => new URLSearchParams(window.location.search).has('enableSocialLoginInEU'),
        [enableSocialLogin],
    );
    const showSocialLogin = enableSocialLogin && (!euRequest || enableSocialLoginInEU);
    return (
        <>
            <Stack gap={2}>
                {enablePasswordLogin && (
                    <Link
                        onClick={() => persistDefaultEmail(userEmail)}
                        underline="none"
                        href={new_user_session_path()}
                    >
                        <ColoredBrandButton onClick={trackEvent('Password login')}>
                            <PasswordLock width={20} height={20} />
                            <span style={{ marginLeft: 5 }}>Log in with Password</span>
                        </ColoredBrandButton>
                    </Link>
                )}
                {enablePasswordlessLogin && (
                    <Link
                        onClick={() => persistDefaultEmail(userEmail)}
                        underline="none"
                        href={login_code_sign_in_path()}
                    >
                        <ColoredBrandButton onClick={trackEvent('Request Code')}>
                            <MagicWand width={20} height={20} />
                            <span style={{ marginLeft: 5 }}>Log in with a one-time code</span>
                        </ColoredBrandButton>
                    </Link>
                )}
                {showSocialLogin && (
                    <a href={user_google_oauth2_omniauth_authorize_path()}>
                        <BrandButton
                            onClick={(e) => {
                                trackEvent('Continue Google')(e);
                                trackLoginSubmit('Google');
                            }}
                        >
                            <Google width={20} height={20} />
                            <span style={{ marginLeft: 5 }}>Continue with Google</span>
                        </BrandButton>
                    </a>
                )}
            </Stack>
        </>
    );
}
