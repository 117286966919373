import { Alert, FormGroup, Stack, TextField } from '@customink/pigment-react';
import { useState } from 'react';
import { AuthTitle } from '~/components/AuthTitle';
import { AuthText } from '~/components/AuthText';
import { create_login_code_path, new_user_registration_path, new_user_session_path } from '~/adapter/window/routes';
import { CSRFHiddenInput } from '~/components/CSRFHiddenInput';
import { Centered } from '~/components/Centered';
import { AuthContainer } from '~/components/AuthContainer';
import { useData } from '~/adapter/browser/useData';
import { LoginAlternatives } from '~/components/LoginAlternatives';
import { FormLabel } from '~/components/FormLabel';
import { Link } from '~/components/Link';
import { FormError } from '~/components/FormError';
import { trackEvent } from '~/adapter/tracking/trackEvent';
import { persistDefaultEmail, useOneTimeDefaultEmail } from '~/components/hooks/useDefaultEmail';
import { OrDivider } from '~/components/OrDivider';
import { AuthButton } from '~/components/AuthButton';
import { trackLinksAndButtons } from '~/adapter/tracking/linksAndButtons';

export function EmailEnter() {
    const { email, errors, euRequest, redirectUri } = useData();

    const defaultEmail = useOneTimeDefaultEmail(email);
    const [userEmail, setUserEmail] = useState(defaultEmail);
    return (
        <AuthContainer>
            <Stack gap={4}>
                <Stack gap={3}>
                    <Stack gap={2}>
                        <AuthTitle>Log In With One-Time Code</AuthTitle>
                        <AuthText>Enter your email to receive a 6-digit code to complete your log in.</AuthText>
                    </Stack>
                    <form action={create_login_code_path()} method="POST" id="email_submit_form">
                        <Stack gap={3}>
                            <Stack gap={2}>
                                <FormGroup>
                                    <FormLabel error={Boolean(errors.email)}>Enter email address</FormLabel>
                                    <TextField
                                        error={Boolean(errors.email)}
                                        autoFocus
                                        type="email"
                                        name="user[email]"
                                        id="user_email"
                                        defaultValue={userEmail}
                                        onChange={(ev) => setUserEmail(ev.target.value)}
                                        helperText={<FormError errors={errors.email} />}
                                        required
                                    />
                                </FormGroup>
                            </Stack>
                            {redirectUri && <input type="hidden" id="redirect_uri" value={redirectUri} />}
                            <CSRFHiddenInput />

                            <AuthButton
                                id="email_submit"
                                variant="primary"
                                size="medium"
                                type="submit"
                                fullWidth
                                onClick={(e) => {
                                    trackEvent('Log In')(e);
                                }}
                            >
                                Continue
                            </AuthButton>
                            <Alert severity="info">
                                One-time codes keep your account secure. You can also{' '}
                                <Link
                                    onClick={() => persistDefaultEmail(userEmail)}
                                    href={new_user_session_path()}
                                    underline="none"
                                >
                                    log in using your password
                                </Link>
                                .
                            </Alert>
                        </Stack>
                    </form>
                </Stack>
                <OrDivider />
                <LoginAlternatives euRequest={euRequest} userEmail={userEmail} enablePasswordlessLogin={false} />
                <>
                    {email ? (
                        <Centered>
                            <Link
                                underline="none"
                                href={new_user_session_path()}
                                onClick={trackEvent('Different Email')}
                            >
                                Log in using a different email
                            </Link>
                        </Centered>
                    ) : (
                        <Centered>
                            <AuthText>
                                Don't have an account?{' '}
                                <Link
                                    underline="none"
                                    href={new_user_registration_path()}
                                    onClick={(e) => {
                                        persistDefaultEmail(userEmail);
                                        trackLinksAndButtons('Sign Up', e);
                                    }}
                                >
                                    Sign up.
                                </Link>
                            </AuthText>
                        </Centered>
                    )}
                </>
            </Stack>
        </AuthContainer>
    );
}
