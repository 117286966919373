/**

 Dimensions

 dimension118: userId of currently logged in user cannot be email see google policies
 dimension7: Yes if we are logged in, here always yes...
 dimension119: Login method oauth provider name or Onsite

 Metrics

 in each method are use to distinguish between event for analytics helper
 all the metrics are set to 1

 metric2: signup success
 metric3: singup fail
 metric4: signup submit
 metric5: login success
 metric6: login fail
 metric7: login submit

 */
import { removeUserId, saveUserId } from './userId';

const CATEGORY = 'Login and Signup';

type LoginMethod = 'Facebook' | 'Google' | 'Onsite';

export function trackSignupSuccess(method: LoginMethod, userId: string) {
    ga('send', {
        hitType: 'event',
        eventCategory: CATEGORY,
        eventAction: 'Signup Success',
        eventLabel: method,
        nonInteraction: false,
        dimension118: userId,
        dimension7: 'Yes',
        dimension119: method,
        metric2: 1,
    });
}

export function trackSignupFail(method: LoginMethod, error: string) {
    ga('send', {
        hitType: 'event',
        eventCategory: CATEGORY,
        eventAction: 'Signup Fail',
        eventLabel: `${method}: ${error}`,
        nonInteraction: false,
        dimension119: method,
        metric3: 1,
    });
}

export function trackSignupSubmit(method: LoginMethod) {
    ga('send', {
        hitType: 'event',
        eventCategory: CATEGORY,
        eventAction: 'Signup Submit',
        eventLabel: method,
        nonInteraction: false,
        dimension119: method,
        metric4: 1,
    });
}

export function trackLoginSuccess(method: LoginMethod, userId: string) {
    saveUserId(userId);
    ga('send', {
        hitType: 'event',
        eventCategory: CATEGORY,
        eventAction: 'Login Success',
        eventLabel: method,
        nonInteraction: false,
        dimension119: method,
        dimension7: 'Yes',
        dimension118: userId,
        metric5: 1,
    });
}

export function trackLoginFail(method: LoginMethod, error: string) {
    ga('send', {
        hitType: 'event',
        eventCategory: CATEGORY,
        eventAction: 'Login Fail',
        eventLabel: `${method}: ${error}`,
        nonInteraction: false,
        dimension119: method,
        metric6: 1,
    });
}

export function trackLoginSubmit(method: LoginMethod) {
    removeUserId();
    ga('send', {
        hitType: 'event',
        eventCategory: CATEGORY,
        eventAction: 'Login Submit',
        eventLabel: method,
        nonInteraction: false,
        dimension119: method,
        metric7: 1,
    });
}
